/**
 * Parses JSON body of the response for success, thows error on failure.
 *
 * @param {Object} result - fetch request's result
 *
 * @returns {Object} The parsed JSON when it exists - or the detailed error.
 */
const apiResponseHandler = async (result) => {
  if (result.status >= 500) {
    throw Error(result.statusText)
  }

  if (result.status >= 400) {
    if (result.status === 404) {
      throw Error(result.statusText)
    }

    const { details, error } = await result.json()

    if (result.status === 403) {
      throw Error(error)
    }

    const fields = Object.keys(details)

    const errorMessages = fields.map((field) => (
      `${field} - ${details[field]}`
    ))

    throw Error(`${result.status} ${error}: \n${errorMessages.join('\n')}`)
  }

  if (result.status === 204) {
    return {}
  }

  return result.json()
}

export default apiResponseHandler
