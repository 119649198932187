import React from 'react'
import { Layout } from 'antd'

import { LogoWithAppSelector } from '../../../_shared/components'

const { Sider } = Layout

const Sidebar = () => (
  <Sider>
    <LogoWithAppSelector />
  </Sider>
)

export default Sidebar
