import React from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Trans } from 'react-i18next'
import { Button } from 'antd'

import { setBinsToSortingBulk } from '../api'

const BinSelector = ({ selectedBins, spin}) => {
  //eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams('')

  const handleselectedBins = () => {
    spin.setPercent(0)
    spin.setIsLoading(true)

    setBinsForSorting(selectedBins)
  }

  const setBinsForSorting = (selectedBinIds) => {
    spin.setPercent(50)
    setBinsToSortingBulk({ sorting_bin_id: selectedBinIds })
      .then((data) => {
        setSearchParams({ sorting_ids: selectedBinIds })

        spin.setPercent(70)
      })
      .catch((error) => {
        console.warn(`Error id: #${error}`)
      })
      .finally(() => {
        spin.setPercent(110)
        spin.setIsLoading(false)
      })
  }

  return (
    <Button
      className="bin-selector-btn"
      disabled={isEmpty(selectedBins) || spin.isLoading}
      loading={spin.isLoading}
      onClick={handleselectedBins}
      size="large"
      type="primary"
    >
      <Trans values={{ count: selectedBins.length }}>sorting_tools:sortingSelectedBins</Trans>
    </Button>
  )
}

BinSelector.propTypes = {
  selectedBins: PropTypes.array,
  spin: PropTypes.object,
}

export default BinSelector
