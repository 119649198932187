import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { signal } from '@preact/signals-react'
import { Route, Routes } from 'react-router-dom'
import { FloatButton, Flex, Layout, Spin } from 'antd'
import { FullscreenExitOutlined, FullscreenOutlined, LoadingOutlined } from '@ant-design/icons'
import { Profile } from '../_shared/components'
import { PartnerWidget, ScannerActions, SideBar } from './components'
import { ScanPage } from './pages'

import { getSortingBinDetails } from './components/sorting-id-input/api'

const { Content, Header, Footer } = Layout

export const currentScanDetails = signal({
  barCodeAction: signal(null),
  data: signal([
    {
      code: null,
      country: null,
    },
    {
      store_id: null,
    },
    {
      comment: null,
      condition: 'ok',
      identity_code: null,
    },
  ]),
  sorting_id: signal(null),
  sortingError: signal(false),
  step: signal(0),
  stepIsLoading: signal(false),
  widget: signal({
    code: null,
    country: null,
  }),
})

const CheckInLayout = ({ barcodes }) => {
  const [isFullScreen, setIsFullScreem] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const savedSortingId = localStorage.getItem('sorting_id')

    if (savedSortingId) {
      getSortingBinDetails(savedSortingId)
        .then(data => {
          if (data)
            currentScanDetails.value.sorting_id.value = data
        })
        .catch(error => {
          localStorage.removeItem('sorting_id')
          currentScanDetails.value.sorting_id.value = null
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [])

  const changefullScreen = () => {
    if (isFullScreen)
      document.exitFullscreen()
    else
      document.body.requestFullscreen()

    setIsFullScreem(!isFullScreen)
  }

  if (isLoading)
    return (
      <Flex align="middle" justify="center" style={{ height: '100vh', marginTop: '10%' }}>
        <Spin indicator={<LoadingOutlined spin style={{ fontSize: 48 }} />} />
      </Flex>
    )

  return (
    <Layout className="checkinPage" style={{ minHeight: '100vh' }}>
      <SideBar barcodes={barcodes} />

      <Layout>
        <Header style={{ background: '#fff', padding: '0 16px' }}>
          <Profile widget={<PartnerWidget />} />
        </Header>

        <Content style={{ margin: '16px' }}>
          <div
            style={{
              background: '#fff',
              minHeight: 640,
              padding: 12,
              position: 'relative',
            }}
          >
            <Routes>
              <Route
                element={<ScanPage barcodes={barcodes} />}
                path="*"
              />
            </Routes>
          </div>
        </Content>

        <Footer style={{ textAlign: 'center' }}>Loop TDS ©{new Date().getFullYear()}</Footer>

        <FloatButton
          icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={changefullScreen}
        />
      </Layout>

      <ScannerActions />
    </Layout>
  )
}

CheckInLayout.propTypes = {
  barcodes: PropTypes.object,
}

export default CheckInLayout
