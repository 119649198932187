import React from 'react'
import { Trans } from 'react-i18next'
import { useAsync } from 'react-async'
import { Col, Row, Result, Spin } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { BatchButton } from '../../components'

import { getSortingBins } from '../../api'

import { currentSortingDetails } from '../../Layout'

const HomePage = () => {
  const country = currentSortingDetails.value.country.value

  const handleSelect = (id) => {
    currentSortingDetails.value.selectedBins.value = currentSortingDetails.value.selectedBins.value.includes(id)
      ? currentSortingDetails.value.selectedBins.value.filter((buttonId) => buttonId !== id)
      : [...currentSortingDetails.value.selectedBins.value, id]
  }

  const sortingBins = useAsync({
    country,
    promiseFn: getSortingBins,
    watch: currentSortingDetails.value.country.value,
  })

  if (sortingBins.isLoading) {
    return (
      <Spin name="loadingSpinnerSelenium" tip="...">
        <div className="content" />
      </Spin>
    )
  }

  if (sortingBins?.data?.sorting_bins?.length === 0)
    return <Result icon={<SmileOutlined />} title={<Trans>sorting_tools:done</Trans>} />

  return (
    <>
      <Row gutter={[16, 16]} justify="left" type="flex">
        {React.Children.toArray(sortingBins.data.sorting_bins.map(bin => (
          <Col>
            <BatchButton
              isSelected={currentSortingDetails.value.selectedBins.value.includes(bin.id)}
              labels={{
                created_at: bin.sorting_event.created_at,
                identity_code: bin.identity_code,
              }}
              onSelect={() => handleSelect(bin.id)}
            />
          </Col>
        )))}
      </Row>
    </>
  )}

export default HomePage
