import React from 'react'
import { useTranslation } from 'react-i18next'

import { currentScanDetails } from '../../Layout'

const PartnerWidget = () => {
  const { t } = useTranslation()

  const country = currentScanDetails.value.data.value[0].country || localStorage.getItem('checkin_step1_country')
  const code = currentScanDetails.value.data.value[0].code || localStorage.getItem('checkin_step1_partnerCode')

  return (
    (code && country)
      ? <span key={currentScanDetails.value.step.value}>{t('checkin:selectedPartner')}: <b>{code}</b> ({country})</span>
      : null
  )
}

export default PartnerWidget
