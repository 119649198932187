import React from 'react'
import PropTypes from 'prop-types'
import { Route, Routes } from 'react-router-dom'
import { Layout } from 'antd'
import { Profile } from '../_shared/components'
import { Sidebar } from './components'
import {
  CompaniesPage,
  Error404Page,
  HomePage,
  ShippingContainerDetailsPage,
  ShippingContainersPage,
  StoresPage,
  UploadMediaPage,
} from './pages'

const { Content, Header, Footer } = Layout

const AdminLayout = ({ barcodes, retailers }) => (
  <Layout style={{ minHeight: '100vh' }}>
    <Sidebar />

    <Layout>
      <Header style={{ background: '#fff', padding: '0 16px' }}>
        <Profile isAdminPage />
      </Header>

      <Content style={{ margin: '16px' }}>
        <div style={{ background: '#fff', minHeight: 360, padding: 24 }}>
          <Routes>
            <Route element={<HomePage />} path="/" />

            <Route
              element={<ShippingContainerDetailsPage barcodes={barcodes} />}
              path="/shipping-containers/:id"
            />

            <Route
              element={<ShippingContainersPage />}
              path="/shipping-containers"
            />

            <Route
              element={<CompaniesPage retailers={retailers} />}
              path="/companies"
            />

            <Route element={<StoresPage />} path="/stores" />

            <Route element={<UploadMediaPage />} path="/upload-media" />

            <Route element={<Error404Page />} path="*" />
          </Routes>
        </div>
      </Content>

      <Footer style={{ textAlign: 'center' }}>Loop TDS ©{new Date().getFullYear()}</Footer>
    </Layout>
  </Layout>
)

AdminLayout.propTypes = {
  barcodes: PropTypes.object,
  retailers: PropTypes.array,
}

export default AdminLayout
