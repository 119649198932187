import React from 'react'
import PropTypes from 'prop-types'
import { Tour } from 'antd'

const SortingError = ({ description, onClose, title }) => (
  <Tour
    closeIcon={null}
    onClose={() => onClose(false)}
    open
    placement="right"
    steps={[{
      description,
      target: document.getElementById('sorting-box'),
      title,
    }]}
  />
)

SortingError.propTypes = {
  description: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
}

export default SortingError
