import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { Layout, Spin } from 'antd'
import BinSelectorFooter from './parts/BinSelector'
import SortingTableFooter from './parts/SortingTable'

const { Footer } = Layout

const FooterWithActions = ({ selectedBins }) => {
  const [searchParams] = useSearchParams('')
  const [isLoading, setIsLoading] = useState(false)
  const [percent, setPercent] = useState(0)

  const spin = {
    isLoading,
    setIsLoading,
    setPercent,
  }

  const sortingInProgress = searchParams.getAll('sorting_ids').length > 0

  return (
    <Footer className="sorting-table-footer">
      {sortingInProgress
        ? <SortingTableFooter selectedBins={selectedBins} spin={spin} />
        : <BinSelectorFooter selectedBins={selectedBins} spin={spin} />
      }

      <Spin fullscreen percent={percent} spinning={isLoading} />
    </Footer>
  )
}

FooterWithActions.propTypes = {
  selectedBins: PropTypes.array,
}

export default FooterWithActions
