import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'

import AdminLayout from './admin/Layout'
import CheckInLayout from './check-in/Layout'
import SortingToolLayout from './sorting-tool/Layout'

import { initReactI18next, withTranslation } from 'react-i18next'
import { useAuth0, hasPermission } from './auth0'

import sortingToolTranslations from './sorting-tool/constants/locales'
import checkinTranslations from './check-in/constants/locales'

const activeLocale = localStorage.getItem('activeLocale') || 'en'

export const appNames = ['admin', 'checkin', 'sorting_tools']

i18n
  .use(initReactI18next)
  .init({
    defaultNS: 'checkin',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: activeLocale,
    ns: ['checkin', 'sorting_tools'],
    resources: {
      en: {
        checkin: checkinTranslations.en,
        sorting_tools: sortingToolTranslations.en,
      },
      fr: {
        checkin: checkinTranslations.fr,
        sorting_tools: sortingToolTranslations.fr,
      },
    },
  })

const App = ({ barcodes, retailers }) => {
  const { getTokenSilently, logout, permissions } = useAuth0()

  useEffect(() => {
    getTokenSilently().then(accessToken => localStorage.setItem('accessToken', accessToken))
  }, [getTokenSilently])

  const userAppPermissions = {
    ...(hasPermission(permissions, ['tds_access_mode:admin'])) && { 'tds_access_mode:admin': <AdminLayout barcodes={barcodes} retailers={retailers} />},
    ...(hasPermission(permissions, ['tds_access_mode:checkin'])) && { 'tds_access_mode:checkin': <CheckInLayout barcodes={barcodes} />},
    ...(hasPermission(permissions, ['tds_access_mode:sorting_tools'])) && { 'tds_access_mode:sorting_tools': <SortingToolLayout barcodes={barcodes} />},
  }

  if (Object.keys(userAppPermissions).length === 0) {
    logout()
    return <>You dont have permission, or something went wrong! Please try again later</>
  }

  const firstValidAppName = Object.keys(userAppPermissions)[0]
  const lastUsedApp = localStorage.getItem('tds-active-app')
  const activeApp = (lastUsedApp && appNames.includes(lastUsedApp)) ? `tds_access_mode:${lastUsedApp}` : firstValidAppName

  return userAppPermissions[activeApp]
}

App.propTypes = {
  barcodes: PropTypes.object,
  retailers: PropTypes.array,
}

export default withTranslation()(App)
