import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Divider, Row, Spin } from 'antd'
import { isEmpty, sumBy } from 'lodash'
import { BinBadge, SortingItem, SortingQuantityModal } from '../../components'

import noImgPackage from '../../../_shared/assets/images/no-image-pack.jpeg'

import { getBin } from '../../api'

import { currentSortingDetails } from '../../Layout'

const SortingPage = ({ barcodeList, ids }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [binStatus, setBinStatus] = useState(ids.map(id => ({ code: null, id, isLoading: true, packageNumber: 0 })))
  const [packages, setPackages] = useState([])

  useEffect(() => {
    if (selectedPackage || currentSortingDetails.value.modalVisiblity.notListedItem.value || isEmpty(currentSortingDetails.value.barCodeAction.value)) return

    if (!packages[currentSortingDetails.value.barCodeAction.value]) {
      currentSortingDetails.value.modalVisiblity.notListedItem.value = true
    }

  //eslint-disable-next-line
  }, [currentSortingDetails.value.barCodeAction.value])

  useEffect(() => {
    if (currentSortingDetails.value.closingInProgress.value) return

    Promise.all(
      ids.map((id, index) =>
        getBin(id)
          .then(data => {
            currentSortingDetails.value.fetchedBins.value[id] = data

            setBinStatus(oldArray => {
              let updatedArray = oldArray
              updatedArray[index] = { code: data.identity_code, id, isLoading: false, packageNumber: sumBy(data.sorting_event.packages, 'quantity')}
              return updatedArray
            })
          })
          .catch(error => {
            currentSortingDetails.value.fetchedBins.value[id] = null
            setBinStatus(oldArray => {
              let updatedArray = oldArray
              updatedArray[index] = { code: null, id, isLoading: false, packageNumber: 0 }
              return updatedArray
            })
          })
      ))
      .finally(() => {
        const mergedPackages = {}
        Object.keys(currentSortingDetails.value.fetchedBins.value).forEach((binId) => {
          const bin = currentSortingDetails.value.fetchedBins.value[binId]
          bin.sorting_event.packages.forEach((pkg) => {
            mergedPackages[pkg.upc] = {
              ...pkg,
              quantity: pkg.quantity + (mergedPackages[pkg.upc] ? mergedPackages[pkg.upc].quantity : 0),
            }
          })
        })

        setPackages(mergedPackages)
        setIsLoading(false)
      })
  }, [ids])

  if (isLoading) return <Spin fullscreen name="loadingSpinnerSelenium" />

  const searchValue = currentSortingDetails.value.search.value.toLowerCase()

  return (
    <>
      <Row>
        <BinBadge bins={binStatus} />
      </Row>

      <Divider style={{ borderColor: '#7cb305'}} variant="dotted">
        <Trans>sorting_tools:packages</Trans>
      </Divider>

      <Row gutter={[16, 16]} justify="left" style={{ marginTop: 30 }} type="flex">
        {React.Children.toArray(Object.keys(packages).map((barcode, index) => {
          const product = barcodeList[barcode]

          if (!product) {
            console.warn(`${barcode} missing`)
            return null
          }

          return (
            <SortingItem
              barcode={barcode}
              image={product.media[0]?.small_url || noImgPackage}
              index={index}
              isHighlighted={product.product_name.toLowerCase().includes(searchValue) || barcode.toLowerCase().includes(searchValue) || product.sorting_key.toLowerCase().includes(searchValue)}
              name={product.product_name || ''}
              quantity={packages[barcode].quantity}
              selectPackage={setSelectedPackage}
            />
          )
        }))}
      </Row>

      {!isEmpty(selectedPackage) &&
        <SortingQuantityModal
          onClose={() => setSelectedPackage(null)}
          selectedPackage={selectedPackage}
        />
      }
    </>
  )}

SortingPage.propTypes = {
  barcodeList: PropTypes.object,
  ids: PropTypes.array,
}

export default SortingPage
