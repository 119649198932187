import React from 'react'
import { useTranslation } from 'react-i18next'
import { Layout, Steps } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { routePropTypes } from '../../../_shared/types'
import { DailyCounter, DraftCounter, SortingIdInput } from '../'
import { LogoWithAppSelector } from '../../../_shared/components'

import './index.css'

import { currentScanDetails } from '../../Layout'

const { Sider } = Layout

const Sidebar = () => {
  const { t } = useTranslation()

  let stepElements = [
    {
      title: t('checkin:step1Title'),
    },
    {
      title: t('checkin:step2Title'),
    },
    {
      title: t('checkin:step3Title'),
    },
    {
      title: t('checkin:step4Title'),
    },
  ]

  if (currentScanDetails.value.stepIsLoading.value) {
    stepElements[currentScanDetails.value.step.value].icon = <LoadingOutlined />
  } else {
    if(stepElements[currentScanDetails.value.step.value])
      stepElements[currentScanDetails.value.step.value].icon = null
  }

  return (
    <Sider>
      <LogoWithAppSelector />

      <Steps
        current={currentScanDetails.value.step.value}
        direction="vertical"
        icon={<LoadingOutlined />}
        items={stepElements}
        status="process"
      />

      <SortingIdInput />

      <DailyCounter />
      <DraftCounter />
    </Sider>
  )
}

Sidebar.propTypes = {
  ...routePropTypes,
}

export default Sidebar
