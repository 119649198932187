import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useCountUp } from 'react-countup'
import { Trans } from 'react-i18next'
import { Col, Button, Flex, Row, Tooltip, Typography } from 'antd'

import { currentSortingDetails } from '../../Layout'

const { Text } = Typography

const SortingItem = ({ barcode, image, index, isHighlighted, name, quantity, selectPackage }) => {
  const [remainingItem, setRemainingItem] = useState(quantity - (currentSortingDetails.value.sortedQuantities.value[barcode] || 0))
  const countUpRef = useRef(null)
  const enterQtyRef = useRef(null)

  const { update } = useCountUp({
    duration: 1,
    end: (quantity - (currentSortingDetails.value.sortedQuantities.value[barcode] || 0)),
    onEnd: ({ pauseResume }) => { setRemainingItem(quantity - (currentSortingDetails.value.sortedQuantities.value[barcode] || 0))},
    ref: countUpRef,
    start: quantity,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (currentSortingDetails.value.modalVisiblity.notListedItem.value || (barcode !== currentSortingDetails.value.barCodeAction.value)) return

    enterQtyRef.current.click()
  //eslint-disable-next-line
  }, [currentSortingDetails.value.barCodeAction.value])

  const handleAllItemSorted = () => {
    setIsLoading(true)

    currentSortingDetails.value.sortedQuantities.value[barcode] = quantity
    currentSortingDetails.value.barCodeAction.value = ''
    update(0)
    localStorage.setItem('sorted_barcode_qty', JSON.stringify(currentSortingDetails.value.sortedQuantities.value))

    setTimeout(() => setIsLoading(false), 200)
  }

  if (remainingItem <= 0) return null

  return (
    <Col
      className="sorting-item-package"
      lg={12}
      md={24}
      sm={24}
      style={{
        opacity: isHighlighted ? 1 : '.2',
        order: isHighlighted ? -1 : index,
        transform: isHighlighted ? 'scale(1)' : 'scale(.9)',
      }}
      xl={8}
      xs={24}
    >
      <Flex align="middle" className="sorting-item" justify="space-between">
        <Col className="item-wrapper">
          <img
            alt="logo"
            src={image}
            style={{ maxWidth: '80px', minWidth: '80px' }}
          />
        </Col>
        <Col className="item-wrapper" style={{ width:'100%' }}>
          <Text strong>{name}</Text>
        </Col>
        <Col align="center" className="item-wrapper">
          <p><Trans>sorting_tools:quantity</Trans></p>

          <Text mark>
            <Tooltip placement="top" title={`${currentSortingDetails.value.sortedQuantities.value[barcode] || 0}`}>
              <span ref={countUpRef} />
            </Tooltip>
          </Text><br />
        </Col>
        <Col className="sorting-button-wrapper item-wrapper">
          <Row gutter={[8, 8]}>
            <Col>
              <Button
                className="sort-button"
                color="green"
                disabled={isLoading}
                onClick={handleAllItemSorted}
                size="large"
              >
                <Trans>sorting_tools:allSorted</Trans>
              </Button>
            </Col>
            <Col>
              <Button
                className="sort-quantity-button"
                disabled={isLoading}
                onClick={() => selectPackage({ barcode, image, name, quantity, update })}
                ref={enterQtyRef}
                size="large"
                type="primary"
              >
                <Trans>sorting_tools:enterQuantity</Trans>
              </Button>
            </Col>
          </Row>
        </Col>
      </Flex>
    </Col>
  )
}

SortingItem.propTypes = {
  barcode: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  selectPackage: PropTypes.func.isRequired,
}

export default SortingItem
