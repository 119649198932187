import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { message, Modal, Input, InputNumber, Form } from 'antd'

import { currentSortingDetails } from '../../Layout'

const NotListedItemModal = (props) => {
  const skuInputRef = useRef(null)
  const qtyInputRef = useRef(null)

  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (currentSortingDetails.value.barCodeAction.value) {
      qtyInputRef.current.focus()
    } else {
      skuInputRef.current.focus()
    }
  }, [])

  const handleFinish = () => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        const qty = form.getFieldValue('quantity')
        const barcode = form.getFieldValue('barcode')

        currentSortingDetails.value.sortedQuantities.value[barcode] = (!!currentSortingDetails.value.sortedQuantities.value[barcode])
          ? currentSortingDetails.value.sortedQuantities.value[barcode] + qty
          : qty

        localStorage.setItem('sorted_barcode_qty', JSON.stringify(currentSortingDetails.value.sortedQuantities.value))

        message.success({
          content: t('sorting_tools:notListedItemAdded'),
        })

        currentSortingDetails.value.barCodeAction.value = ''

        setTimeout(() => props.onCancel(), 200)
      })
      .catch(error => {
        message.error({
          content: error.errorFields[0].errors[0],
        })
        return false
      })
  }

  return (
    <Modal
      cancelText={t('sorting_tools:cancel')}
      okButtonProps={{ className: 'sort-button' }}
      okText={t('sorting_tools:ok')}
      onOk={handleFinish}
      open
      {...props}
      title={t('sorting_tools:noListedItemFound')}
    >
      <Form
        form={form}
        initialValues={{
          barcode: currentSortingDetails.value.barCodeAction.value,
          quantity: 1,
        }}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Form.Item
          label={t('sorting_tools:barcode')}
          name="barcode"
          rules={[{ message: t('sorting_tools:barcodeRequired'), required: true }]}
          validateTrigger="onBlur"
        >
          <Input
            onPressEnter={handleFinish}
            ref={skuInputRef}
            size="large"
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          label={t('sorting_tools:quantity')}
          name="quantity"
          rules={[{ message: t('sorting_tools:quantityRequired'), required: true }]}
          validateTrigger="onBlur"
        >
          <InputNumber
            min={1}
            onFocus={(event) => event.target.select()}
            onPressEnter={handleFinish}
            ref={qtyInputRef}
            size="large"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

NotListedItemModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default NotListedItemModal
