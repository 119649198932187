import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'
import { Badge, Flex, Col, Typography } from 'antd'

const { Title } = Typography

const BatchButton = ({ bins }) => (
  <Flex vertical>
    <Title level={5}><Trans>sorting_tools:youWorkingOnThis</Trans></Title>
    <Col className="bin-badges" size="middle">
      {bins.map(({ code, id, isLoading, packageNumber}) => (
        <Badge
          key={id}
          status={isLoading ? 'processing' : (code) ? 'success' : 'error'}
          style={{ margin: 5 }}
          text={`${code} (${packageNumber})` || <>#{id} {isLoading && <LoadingOutlined />}</>}
        />
      ))}
    </Col>
  </Flex>

)

BatchButton.propTypes = {
  bins: PropTypes.array.isRequired,
}

export default BatchButton
