import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { Button, Col, Form, Input, message, Popconfirm, Tooltip, Row } from 'antd'
import { CheckCircleOutlined, SaveOutlined } from '@ant-design/icons'

import { currentScanDetails } from '../../Layout'
import { normalizeValue } from '../../pages/scan/Scan'
import { createSortingBin, setSortingBinToReady } from './api'

import './index.css'

const SortingIdInput = () => {
  const { t } = useTranslation()

  const sortingIdRef = useRef(null)
  const [form] = Form.useForm()

  const onSave = () => {
    const country = currentScanDetails.value.data.value[0].country || localStorage.getItem('checkin_step1_country')
    const identity_code = sortingIdRef.current.input.value

    if (!country || !identity_code) {
      message.open({
        content: t('sortingIdEmptyError'),
        type: 'error',
      })
      currentScanDetails.value.sortingError.value = true
      return
    }

    const fields = {
      country,
      identity_code,
    }

    createSortingBin(fields)
      .then(data => {
        currentScanDetails.value.sorting_id.value = data
        localStorage.setItem('sorting_id', data.id)
        currentScanDetails.value.sortingError.value = false
      })
      .catch(error => {
        message.open({
          content: error.message,
          type: 'error',
        })
      })
      .finally(() => {
        currentScanDetails.value.stepIsLoading.value = false
      })
  }

  const onClose = () => {
    setSortingBinToReady(currentScanDetails.value.sorting_id.value.id)
      .then(() => {
        message.open({
          content: t('sortingIdClosed'),
          type: 'success',
        })

        currentScanDetails.value.sorting_id.value = null
        currentScanDetails.value.sortingError.value = false
        sortingIdRef.current.input.value = ''
        localStorage.removeItem('sorting_id')

        form.setFieldsValue('sorting_id', '')

        sortingIdRef.current.input.disabled = false
        sortingIdRef.current.focus()
      })
      .catch(error => {
        message.open({
          content: error.message,
          type: 'error',
        })
      })
  }

  const hasSortingIdValue = !isEmpty(currentScanDetails.value.sorting_id.value)

  return (
    <Row className="sorting-box" id="sorting-box">
      <Col span={24}>
        <Form
          form={form}
          initialValues={{
            sorting_id: currentScanDetails.value.sorting_id.value?.identity_code,
          }}
          layout="vertical"
        >
          <Tooltip arrow placement="right" title={hasSortingIdValue ? <Trans>sortingIdTooltip</Trans> : ''}>
            <Form.Item
              label={<Trans>sortingInputLabel</Trans>}
              name="sorting_id"
              normalize={normalizeValue}
            >
              <Input
                className="sorting-id-input"
                disabled={hasSortingIdValue}
                onPressEnter={onSave}
                ref={sortingIdRef}
                size="medium"
              />
            </Form.Item>
          </Tooltip>

          {hasSortingIdValue ? (
            <Popconfirm
              cancelText={<Trans>no</Trans>}
              description={<Trans>sortingCloseConfirmDesc</Trans>}
              okText={<Trans>yes</Trans>}
              onConfirm={onClose}
              placement="right"
              title={<Trans values={{ sortingID: currentScanDetails.value.sorting_id.value?.identity_code }}>sortingCloseConfirmTitle</Trans>}
            >
              <Button block icon={<CheckCircleOutlined />} size="medium" type="ready">
                <Trans>sortingCloseLabel</Trans>
              </Button>
            </Popconfirm>
          ) : (
            <Button block icon={<SaveOutlined />} onClick={onSave} size="medium" type="primary">
              <Trans>sortingSaveLabel</Trans>
            </Button>
          )}
        </Form>
      </Col>
    </Row>
  )
}

export default SortingIdInput
