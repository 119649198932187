import React, { useRef } from 'react'
import {
  Alert,
  Col,
  Form,
  message,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-async'
import { unionBy } from 'lodash'
import { getPartners } from '../api'
import { NavigateButton, SortingError, updateDraftCounters } from '../../../components'

import { currentScanDetails } from '../../../Layout'

const CountryAndPartnerStep = () => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const countryInputRef = useRef(null)
  const partnerInputRef = useRef(null)

  const partners = useAsync({
    promiseFn: getPartners,
  })

  const selectedCountry = Form.useWatch('country', form)

  if (partners.isLoading) {
    return (
      <Spin name="loadingSpinnerSelenium" tip="Loading partners...">
        <div className="content" />
      </Spin>
    )
  }

  if (partners.data?.length === 0) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert message={t('checkin:step1Error')} type="error" />
      </Space>
    )
  }

  const setLocaleStorage = (country, code) => {
    localStorage.setItem('checkin_step1_country', country)
    localStorage.setItem('checkin_step1_partnerCode', code)
  }

  if(!localStorage.getItem('checkin_step1_country')) {
    localStorage.setItem('checkin_step1_country', partners.data[0].country)
  }

  if(!localStorage.getItem('checkin_step1_partnerCode')) {
    localStorage.setItem('checkin_step1_partnerCode', partners.data[0].code)
  }

  if (partners?.data?.length === 1) {
    setTimeout(() => {
      goNextStep()
    }, 500)
  }

  const countryOptions = partners.data.map(({ country }) => ({ label: country, value: country }))
  const partnerOptions = partners.data.map(({ country, code }, index) => ({ country, label: code, value: index }))

  const countryOnChange = (value) => {
    if (currentScanDetails.value.sorting_id.value && currentScanDetails.value.sorting_id.value.country !== value) {
      currentScanDetails.value.sortingError.value = true
      form.setFieldValue('country', currentScanDetails.value.sorting_id.value.country)

      return
    }

    const firstPartner = partners.data.find(partner => partner.country === value)

    setLocaleStorage(firstPartner.country, firstPartner.code)

    form.setFieldValue('partnerCode', firstPartner.code)

    if (countryInputRef.current) {
      countryInputRef.current.blur()
    }
  }

  const partnerOnChange = (partnerIndex) => {
    setLocaleStorage(partners.data[partnerIndex].country, partners.data[partnerIndex].code)

    if (partnerInputRef.current) {
      partnerInputRef.current.blur()
    }
  }

  const goNextStep = () => {
    currentScanDetails.value.data.value[0] = {
      code: localStorage.getItem('checkin_step1_partnerCode'),
      country: localStorage.getItem('checkin_step1_country'),
    }

    currentScanDetails.value.stepIsLoading.value = false
    currentScanDetails.value.step.value = 1

    updateDraftCounters()

    message.destroy()
    message.open({
      content: t('checkin:step1Success', { country: localStorage.getItem('checkin_step1_country'), partner: localStorage.getItem('checkin_step1_partnerCode') }),
      type: 'success',
    })
  }

  if (currentScanDetails.value.barCodeAction.value?.includes('::loop::goNextStep')) {
    currentScanDetails.value.barCodeAction.value = null

    setTimeout(() => {
      goNextStep()
    }, 200)
  }

  const initialValues = {
    country: localStorage.getItem('checkin_step1_country') || partners.data[0].country,
    partnerCode: localStorage.getItem('checkin_step1_partnerCode') || partners.data[0].code,
  }

  return (
    <Row>
      <Col span={14}>
        <Form
          form={form}
          initialValues={initialValues}
          layout="vertical"
          size="large"
        >
          <Form.Item label={t('checkin:selectACountry')} name="country">
            <Select
              onChange={countryOnChange}
              options={unionBy(countryOptions, 'value')}
              ref={countryInputRef}
              showSearch
              size="large"
            />
          </Form.Item>

          <Form.Item label={t('checkin:selectAPartner')} name="partnerCode">
            <Select
              onChange={partnerOnChange}
              options={partnerOptions.filter(({ country }) => selectedCountry === country)}
              ref={partnerInputRef}
              showSearch
              size="large"
            />
          </Form.Item>
        </Form>
      </Col>

      <Col span={10}>
        <NavigateButton
          label={t('checkin:next')}
          loading={currentScanDetails.value.stepIsLoading.value}
          onClick={goNextStep}
          qrAction="::loop::goNextStep"
        />
      </Col>

      {currentScanDetails.value.sortingError.value && <SortingError description={t('sortingIdCountryError')} onClose={() => currentScanDetails.value.sortingError.value = false} title={t('sortingIdError')} />}
    </Row>
  )
}

export default CountryAndPartnerStep
