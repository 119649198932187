import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

import { currentSortingDetails } from '../../Layout'

const activeCountry = localStorage.getItem('sorting_country')

const SortingToolLayout = ({ countries, isDisabled }) => {
  const countryOnChange = (value) => {
    currentSortingDetails.value.country.value = value
    localStorage.setItem('sorting_country', value)
  }

  return (
    <Select
      defaultValue={activeCountry || currentSortingDetails.value.country.value}
      disabled={isDisabled}
      onChange={countryOnChange}
      options={countries}
      showSearch
      size="large"
      style={{ width: 250 }}
    />
  )
}

SortingToolLayout.propTypes = {
  countries: PropTypes.array,
  isDisabled: PropTypes.bool,
}

export default SortingToolLayout
