import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Col,
  Modal,
  Row,
} from 'antd'
import { NavigateButton } from '../../../../components'

import { currentScanDetails } from '../../../../Layout'

const OneTimeConfirmModal = ({ isVisible, onCancelFn, onOkFn }) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeIcon={false}
      footer={[]}
      onCancel={() => {}}
      open={isVisible}
      title={t('checkin:oneTimeConfirmQuestion')}
      width="80%"
    >
      <Row>
        <Col offset={4} span={8}>
          <NavigateButton
            disabled={currentScanDetails.value.stepIsLoading.value}
            label={t('checkin:oneTimeConfirmCancel')}
            onClick={() => onCancelFn()}
            qrAction="::loop::goBacktoEdit"
          />
        </Col>
        <Col span={8}>
          <NavigateButton
            disabled={currentScanDetails.value.stepIsLoading.value}
            label={t('checkin:oneTimeConfirmOk')}
            onClick={() => onOkFn()}
            qrAction="::loop::finish"
          />
        </Col>
      </Row>
    </Modal>
  )
}

OneTimeConfirmModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancelFn: PropTypes.func,
  onOkFn: PropTypes.func,
}

export default OneTimeConfirmModal
