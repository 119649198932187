import React from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { last, mergeWith, pickBy, pull } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Form, Input, message, Popconfirm } from 'antd'
import { NotListedItemModal } from '../../'

import { currentSortingDetails } from '../../../Layout'
import { setBinSortingStatusToClose } from '../api'

const { Search } = Input

export const SortingTable = ({ spin }) => {
  //eslint-disable-next-line
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams('')
  const currentSearchParams = searchParams.getAll('sorting_ids') || []

  let binResultBody = {}

  const resetApp = () => {
    currentSortingDetails.value.selectedBins.value = []
    currentSortingDetails.value.sortedQuantities.value = {}
    currentSortingDetails.value.fetchedBins.value = {}
    currentSortingDetails.value.search.value = ''

    localStorage.removeItem('sorted_barcode_qty')
    setTimeout(() => {
      currentSortingDetails.value.closingInProgress.value = false
      spin.setIsLoading(false)
    }, 500)
  }

  const onSearch = (value, event) => {
    event.preventDefault()

    currentSortingDetails.value.search.value = (value.length < 3) ? '' : value
  }

  const handleCloseBatch = () => {
    spin.setIsLoading(true)

    let remainingItems = Object.assign({}, currentSortingDetails.value.sortedQuantities.value)
    const bins = currentSortingDetails.value.fetchedBins.value
    const calculatedPercentageStep = 110 / bins.length
    binResultBody = {}

    Object.keys(bins).forEach(binId => {
      const binPackages = bins[binId].sorting_event.packages

      binResultBody[binId] = binResultBody[binId] || {}

      binPackages.forEach(pckg => {
        if (remainingItems[pckg.upc] && remainingItems[pckg.upc] > 0) {
          if (remainingItems[pckg.upc] > pckg.quantity) {
            binResultBody[binId][pckg.upc] = pckg.quantity
            remainingItems[pckg.upc] = remainingItems[pckg.upc] - pckg.quantity
          } else {
            binResultBody[binId][pckg.upc] = remainingItems[pckg.upc]
            remainingItems[pckg.upc] = remainingItems[pckg.upc] - pckg.quantity
          }
        }
      })
    })

    const notListedItems = pickBy(remainingItems , (itemQty) => itemQty > 0)

    binResultBody[last(Object.keys(binResultBody))] = mergeWith(
      binResultBody[last(Object.keys(binResultBody))],
      notListedItems,
      (objValue = 0, srcValue = 0) => objValue + srcValue
    )

    currentSortingDetails.value.closingInProgress.value = true
    setBinsStatusForClose(0, calculatedPercentageStep)
  }

  const setBinsStatusForClose = (index, calculatedPercentageStep) => {
    const binId = Object.keys(currentSortingDetails.value.fetchedBins.value)[index]

    const result = {
      ...binResultBody[binId],
    }

    setBinSortingStatusToClose({ result, sorting_bin_id: binId })
      .then(() => {
        const newParams = pull(currentSearchParams, binId)
        setSearchParams({ sorting_ids: newParams })
      })
      .catch((error) => {
        console.warn(`Error id: #${binId}`)
        console.warn(error)
      })
      .finally(() => {
        const nextIndex = index + 1

        spin.setPercent((nextIndex) * calculatedPercentageStep)

        if (nextIndex < Object.keys(binResultBody).length) {
          setBinsStatusForClose(nextIndex, calculatedPercentageStep)
        } else {
          resetApp()
          message.success({
            content: <Trans>sorting_tools:done</Trans>,
          })
        }
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Form
        className="search-form"
        style={{
          display: 'flex',
          flex: 1,
          gap: '10px',
        }}
      >
        <Form.Item style={{ flex: 1 }}>
          <Search
            allowClear
            onSearch={onSearch}
            placeholder={t('sorting_tools:searchInput')}
            size="large"
          />
        </Form.Item>
      </Form>

      <Button onClick={() => currentSortingDetails.value.modalVisiblity.notListedItem.value = true} size="large" style={{ flex: 1, margin: '0 10px' }} type="primary">
        <Trans>sorting_tools:noListedItemFound</Trans>
      </Button>

      <Popconfirm
        cancelText={<Trans>sorting_tools:cancel</Trans>}
        description={<Trans>sorting_tools:areYouSureClose</Trans>}
        okText={<Trans>sorting_tools:ok</Trans>}
        onConfirm={handleCloseBatch}
        title={<Trans>sorting_tools:closeBatchPopup</Trans>}
      >
        <Button className="close-batch-btn" size="large" style={{ flex: 1 }}>
          <Trans>sorting_tools:closeBatch</Trans>
        </Button>
      </Popconfirm>

      {currentSortingDetails.value.modalVisiblity.notListedItem.value &&
        <NotListedItemModal onCancel={() => currentSortingDetails.value.modalVisiblity.notListedItem.value = false} />
      }
    </div>
  )
}

SortingTable.propTypes = {
  spin: PropTypes.object,
}

export default SortingTable
