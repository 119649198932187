import tote from './loop_tote.png'
import bottle from './qrsticker.png'
import noImageBottle from './no-image-bottle.jpeg'
import noImagePack from './../../../_shared/assets/images/no-image-pack.jpeg'

const images = {
  bottle,
  noImageBottle,
  noImagePack,
  tote,
}

export default images
