import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Button } from 'antd'

dayjs.extend(utc)

const BatchButton = ({ onSelect, labels, isSelected }) => (
  <Button
    className="batch-button"
    onClick={onSelect}
    style={{ height: 'auto', margin: '5px' }}
    type={isSelected ? 'primary' : 'default'}
  >
    {dayjs.utc(labels.created_at).format('YYYY-MM-DD HH:mm:ss')}
    <strong>{labels.identity_code}</strong>
  </Button>
)

BatchButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default BatchButton
