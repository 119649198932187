import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { hasPermission, useAuth0 } from '../../../auth0'
import { MENU_ELEMENTS } from '../../constants'
import { routePropTypes } from '../../../_shared/types'
import { LogoWithAppSelector } from '../../../_shared/components'

const Sidebar = () => {
  const { Sider } = Layout
  const [isCollapse, setCollapse] = useState(false)
  const location = useLocation()
  const { pathname } = location

  const { permissions } = useAuth0()
  const userMenuItems = MENU_ELEMENTS.filter(menuItem => hasPermission(permissions, menuItem.permission))

  return (
    <Sider collapsed={isCollapse} collapsible onCollapse={() => setCollapse(!isCollapse)}>
      <LogoWithAppSelector />

      <Menu items={userMenuItems} key="user" mode="inline" selectedKeys={[pathname]} theme="dark" />
    </Sider>
  )
}

Sidebar.propTypes = {
  ...routePropTypes,
}

export default Sidebar
