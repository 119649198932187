import { useEffect } from 'react'
import mitt from 'mitt'

import { currentSortingDetails } from '../../Layout'

const events = mitt()

class BarcodeScanner {
  inputString = ''
  initialize = () => {
    document.addEventListener('keypress', this.keyup)
  }
  close = () => document.removeEventListener('keypress', this.keyup)
  keyup = (event) => {
    if (event.key === 'Enter') {
      events.emit('onbarcodescaned', this.inputString)

      event.preventDefault()
      event.stopPropagation()

      this.inputString = ''
    } else {
      this.inputString += event.key
    }
  }
}

const ScannerActions = (props) => {
  useEffect(() => {
    const barcode = new BarcodeScanner()

    barcode.initialize()

    return () => {
      barcode.close()
    }
  }, [])

  useEffect(() => {
    const scanHandler = code => currentSortingDetails.value.barCodeAction.value = code

    events.on('onbarcodescaned', scanHandler)

    return () => {
      events.off('onbarcodescaned', scanHandler)
    }
  }, [])

  return null
}

export default ScannerActions
