import { getHeaders, apiResponseHandler } from '../../../_shared/api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/sorting_bins'

export const createSortingBin = (fields) => fetch(
  ENDPOINT_BASE_URL,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'POST',
  },
).then(apiResponseHandler)

export const getSortingBinDetails = (id) => fetch(
  `${ENDPOINT_BASE_URL}/${id}`,
  { headers: getHeaders() },
).then(apiResponseHandler)

export const setSortingBinToReady = (sorting_id) => fetch(
  `${ENDPOINT_BASE_URL}/${sorting_id}/ready`,
  {
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)
