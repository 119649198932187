import { getHeaders, apiResponseHandler } from '../_shared/api-utils'

export const CONFIG_ENDPOINT = '/api/admin/sorting_bins'

export const getUserSortingBins = ({ userId }) => fetch(
  `${CONFIG_ENDPOINT}?status=in_sorting&sorting_user_uid=${userId}`,
  { headers: getHeaders() },
).then(apiResponseHandler)

export const getSortingBins = ({ country }) => fetch(
  `${CONFIG_ENDPOINT}?country=${country}&status=ready_for_sorting`,
  { headers: getHeaders() },
).then(apiResponseHandler)

export const getBin = (id) => fetch(
  `${CONFIG_ENDPOINT}/${id}`,
  { headers: getHeaders() },
).then(apiResponseHandler)

export const getPartners = () => fetch(
  '/api/admin/partners',
  { headers: getHeaders() },
).then(apiResponseHandler)
